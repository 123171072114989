import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, DefaultOptions, InMemoryCache, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { envs } from '@shared/envs/env';
import { provideNamedApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';

export const graphqlProvider: ApplicationConfig['providers'] = [
  provideNamedApollo(() => {
    const httpLink = inject(HttpLink);
    const http = httpLink.create({
      uri: envs.graphqlApiUrl,
    });

    const ws = new GraphQLWsLink(
      createClient({
        url: envs.wsUrl,
      }),
    );

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === Kind.OPERATION_DEFINITION &&
          definition.operation === OperationTypeNode.SUBSCRIPTION
        );
      },
      ws,
      http,
    );

    const cache = new InMemoryCache({
      addTypename: false,
    });

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    };

    const apiClient: ApolloClientOptions<any> = {
      cache,
      link,
      defaultOptions,
    };

    return {
      default: apiClient,
      apiClient,
    };
  }),
];
