import {
  HttpEventType,
  HttpHandlerFn,
  HttpRequest,
  HttpStatusCode,
  type HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { constantVariables } from '@shared/general/configs';
import { IErrorResponse } from '@shared/models/general.interface';
import { SnackbarService, SnackbarType } from '@shared/services/snackbar.service';
import { TokenService } from '@shared/services/token.service';
import { BehaviorSubject, catchError, filter, map, switchMap, take } from 'rxjs';

let isRefreshing = false;
const refreshTokenSubject = new BehaviorSubject<any>(null);

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);
  const snackbar = inject(SnackbarService);

  return next(req).pipe(
    map((res) => {
      if (res.type === HttpEventType.Response) {
        if (res?.body) {
          const operationName = (req.body as any)?.operationName
            ? (req.body as any).operationName
            : '';
          const errors = (res.body as any).errors;
          if (errors?.length) {
            let presentedError: IErrorResponse;
            presentedError = {
              error: errors[0]?.extensions?.code,
              message: errors[0]?.extensions?.originalError?.message
                ? errors[0]?.extensions?.originalError?.message
                : errors[0].message,
              statusCode: errors[0]?.extensions?.originalError?.statusCode,
              operationName,
            };
            throw presentedError;
          }
        }
      }
      return res;
    }),
    catchError((e) => {
      console.error(e);
      if (e.statusCode === HttpStatusCode.Unauthorized) {
        if (e.operationName === 'refreshToken' || e.operationName === 'requestLogin') {
          throw e;
        }
        return handle401Error(tokenService, req, next);
      } else if (e.statusCode === HttpStatusCode.NotFound) {
        // generalService.router.navigateByUrl('/error/404');
      }
      snackbar.showSnackbar(e.message + ' ' + e.statusCode, SnackbarType.error);
      throw e;
    }),
  );
};

const handle401Error = (
  tokenService: TokenService,
  request: HttpRequest<any>,
  next: HttpHandlerFn,
) => {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshTokenSubject.next(null);

    return tokenService.refreshToken().pipe(
      switchMap((res) => {
        if (res.data?.refreshToken?.statusCode === HttpStatusCode.Ok) {
          isRefreshing = false;
          refreshTokenSubject.next(localStorage.getItem(constantVariables.accessToken));
          return next(request);
        } else {
          tokenService.logout();
          return next(request);
        }
      }),
      catchError((err) => {
        tokenService.logout();
        isRefreshing = false;
        throw err;
      }),
    );
  }

  return refreshTokenSubject.pipe(
    filter((token) => token !== null),
    take(1),
    switchMap(() => next(request)),
  );
};
